import Text from '@deprecated-ui/common/Text';
import device from '@deprecated-ui/_style/device';
import styled from 'styled-components';
import { Wrapper, SectionTitle } from '../styled';

const SectionWrapper = styled(Wrapper)`
  align-items: center;
  flex-direction: column-reverse;

  @media ${device.tablet} {
    flex-direction: column;
  }

  @media ${device.laptop} {
    flex-direction: row;

    .small {
      padding-right: 50px;
      width: 40%;
    }

    .big {
      padding-left: 50px;
      width: 60%;
    }
  }
`;

const Title = styled(SectionTitle).attrs({
  mb: '50px',
})`
  margin-left: auto;
  margin-right: auto;

  @media ${device.laptop} {
    font-size: 36px;
    margin-left: 0;
    margin-right: unset;
    text-align: left;
  }

  @media ${device.laptopL} {
    font-size: 6.5vh;
  }
`;

const Description = styled(Text).attrs({
  family: 'light',
  height: 1.5,
})`
  font-size: 17px;
  margin: 20px 0;
  text-align: center;

  @media ${device.laptop} {
    font-size: 19px;
    margin: 5vh 0;
    text-align: left;
  }
`;

const StepWrapper = styled.div`
  align-items: end;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: auto;

  @media ${device.laptop} {
    margin: 0;
  }

  img {
    width: 100%;
  }

  .scaleUp:nth-child(1) {
    animation-delay: 0.3s;
  }

  .scaleUp:nth-child(2) {
    animation-delay: 0.5s;
  }

  .scaleUp:nth-child(3) {
    animation-delay: 0.7s;
  }

  .scaleUp:nth-child(4) {
    animation-delay: 0.9s;
  }

  .scaleUp:nth-child(5) {
    animation-delay: 1.1s;
  }
`;

export { SectionWrapper, Title, Description, StepWrapper };
